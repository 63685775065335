import React from 'react';
import BlogList from '../components/BlogList';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import Pager from 'react-pager';
import { Helmet } from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby';

// Icons
import { FaWind, FaTv, FaWifi, FaBath, FaUtensils, FaUserAlt } from 'react-icons/fa';

class Blog extends React.Component {
  constructor(props){
    super(props);

    this.handlePageChanged = this.handlePageChanged.bind(this);

    this.state = {
      total:  11,
      current: 7,
      visiblePage: 3
    };
  }

  handlePageChanged(newPage) {
    this.setState({ current: newPage });
  }

  render (){
    return(
    <Layout>
      <section id="testimonials" className="py-5 lg:py-10">
      <Helmet title="Kost Eksklusif Mara Juara Depok" defer={false} />
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">Artikel</h2>
          <LabelText className="mb-8 text-gray-600 text-center">Dapatkan informasi terkini seputar kehidupan kost</LabelText>
          <div className="flex flex-col md:flex-row md:-mx-3"> 
            <div className="flex-1 px-3 lg:mx-20">
              <StaticQuery query ={
                graphql
                `
                {
                  allWordpressWpPortfolio(
                    sort: {fields: date, order: DESC}
                    
                  ) {
                    edges {
                      node {
                        title
                        date
                        excerpt
                        slug
                        path
                        featured_media {
                          source_url
                        }
                      }
                    }
                  }
                }
                `
              }render={props => (
                <div>
                  {props.allWordpressWpPortfolio.edges.map(item=>(
                    <div key={item.node.title} className="flex-1 px-3">
                      <BlogList customer={item.node} />
                    </div>
                  ))}
                  {/* <Pager
                    total={this.state.total}
                    current={this.state.current}
                    visiblePages={this.state.visiblePage}
                    titles={{ first: '<|', last: '>|' }}
                    className="pagination-sm pull-right"
                    onPageChanged={this.handlePageChanged}
                  /> */}
                </div>
              )} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
    );
  }
}

export default Blog